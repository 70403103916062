import React, { useState } from 'react'
import { IMenu } from '../../Header.interface'
import * as S from './style'
import useDataLayerHeader from '../../../../hooks/useDataLayer/dataLayerHeader'

import IcChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import IcSignIn from '@interco/icons/core/action-navigation/ic_sign_in'

const WIDTH_MD = 768

const MenuComponent: React.FC <IMenu> = ({
  windowWidth,
  data,
}: IMenu): React.ReactElement => {
  const [ sendDatalayerHeaderEvent ] = useDataLayerHeader()
  const [ menuActive, setMenuActive ] = useState<boolean>(false)
  const [ mainActiveOption, setMainActiveOption ] = useState<string>('not-selected')
  const isOpen = (mainActiveOption && mainActiveOption !== 'not-selected' && mainActiveOption !== '')
  const [ currentActiveItems, setCurrentActiveItems ] = useState(data)

  function toggleMenu (evt: React.MouseEvent) {
    evt.preventDefault()
    setMenuActive(!menuActive)
    setMainActiveOption('')
  }

  const hideMainMenu = React.useCallback((evt: React.MouseEvent) => {
    evt.preventDefault()
    setMainActiveOption('')
    evt.stopPropagation()
  }, [])

  const menuDefinition = Object.keys(currentActiveItems).map((item: string) => {
    const currentItem = currentActiveItems[item]
    const titleMenu = currentItem.title
    let routeTag = <></>

    routeTag = (
      <S.MenuOption
        href={currentItem.link}
        onClick={(evt: React.MouseEvent) => {
          sendDatalayerHeaderEvent({
            section: 'null',
            element_action: 'click button',
            element_name: currentItem.title,
            c_page: window.location.href,
            redirect_url: currentItem.link,
          })
        }}
      >
        <div className='d-flex justify-content-between w-100'>
          <div>{titleMenu}</div>
          <div>
            {titleMenu === 'Vagas abertas' ? (windowWidth < WIDTH_MD ? <IcSignIn height={24} width={24} color='#FF7A00' /> : '')
              : (windowWidth < WIDTH_MD ? <IcChevronRight height={24} width={24} color='#FFFFFF' /> : '')
            }
          </div>
        </div>
      </S.MenuOption>
    )

    return (
      <li className='position-relative' key={item}>
        {routeTag}
      </li>
    )
  })

  return (
    <S.Menu active={menuActive}>
      {typeof window !== 'undefined' && windowWidth < WIDTH_MD &&
        (
          <S.MenuBurger onClick={(evt: React.MouseEvent) => toggleMenu(evt)} aria-label='menu'>
            <span />
            <span />
            <span />
          </S.MenuBurger>
        )
      }
      <S.MenuContainer className='list-unstyled'>
        {menuDefinition}
      </S.MenuContainer>
      {isOpen && <div className='backdrop-area' onClick={(evt: React.MouseEvent<HTMLDivElement>) => hideMainMenu(evt)} />}
    </S.Menu>
  )
}

export default MenuComponent
