import React, { ReactNode } from 'react'

import GlobalStyle from '../../styles/global'

// Components
import Header from '../Header'
import HeaderGoTech from '../HeaderGoTech'

import Footer from '@interco/inter-frontend-lib-footer/lib/site'

import SEO from '../Seo'

interface ILayout {
  pageContext: {
    metaTitle: string;
    metaDescription: string;
  };
  children: ReactNode;
  isGoTech?: boolean;
}

const Layout: React.FC<ILayout> = ({ children, pageContext, isGoTech }: ILayout) => {
  return (
    <>
      <GlobalStyle />
      <SEO title={pageContext.metaTitle} description={pageContext.metaDescription} />
      {isGoTech ? <HeaderGoTech /> : <Header />}
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
