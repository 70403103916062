import { createGlobalStyle } from 'styled-components'
import reset from './reset'
import { white, gray, grayscale, orange, generateColorClasses } from './colors'
import { generateFontSizeClasses, generateFontWeightClasses, generateLineHeightClasses } from './fonts'
import { generateBorderRadiusClasses } from './radius'
import 'react-multi-carousel/lib/styles.css'
import '../assets/sass/global.scss'

const globalStyle = createGlobalStyle`
  ${reset}

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Citrina', Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  * {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
  }

  .fill-white {
    fill: ${white};
  };

  .gatsby-image-wrapper {
    width: 100%;
  }

  ${generateColorClasses(orange, 'orange')}
  ${generateColorClasses(gray, 'gray')}
  ${generateColorClasses(grayscale, 'grayscale')}
  ${generateFontWeightClasses()}
  ${generateFontSizeClasses()}
  ${generateLineHeightClasses()}
  ${generateBorderRadiusClasses()}
`

export default globalStyle
