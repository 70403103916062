import { keyframes } from 'styled-components'
export const animateSpeed = '500ms'

export const animatedLeft = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
`
export const dropMenu = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 100%;
  }
`
