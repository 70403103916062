/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo } from 'react'
import Link from './components/Link'
import Menu from './components/Menu'
import useScroll from '../../hooks/useScroll'
import { IHeaderProps } from './Header.interface'
import GlobalStyles from '../../styles/global'
import * as S from './style'
import useWidth from '../../hooks/window/useWidth'
import InterOrange from '../../assets/images/inter.svg'
import data from './components/Menu/Menu.json'
import useDataLayerHeader from '../../hooks/useDataLayer/dataLayerHeader'

/**
 * Função que gera o componente de Header.
 * @param props IHeaderProps
 */
const HeaderSiteComponent: React.FC<IHeaderProps> = ({
  colored,
}: IHeaderProps): React.ReactElement => {
  const scrollPositionY = useScroll(100)
  const fixedHeader = scrollPositionY > 70 || colored
  const globalStyle = useMemo(() => <GlobalStyles />, [])
  const windowWidth: any = useWidth(200)
  const [ sendDatalayerHeaderEvent ] = useDataLayerHeader()

  return (
    <>
      {globalStyle}
      <S.Header
        className='py-4'
      >
        <S.HeaderContainer>
          <div className='row d-flex justify-content-between'>
            <S.Logo
              className='col-4'
              onClick={() => {
                sendDatalayerHeaderEvent({
                  section: 'null',
                  element_action: 'click button',
                  element_name: 'Logo Interdtvm',
                  c_page: window.location.href,
                  redirect_url: `${process.env.SITE_URL}`,
                })
              }}
            >
              <Link
                className='d-inline-block'
                to={`${process.env.SITE_URL}`}
                title='Inter'
              >
                <img src={InterOrange} alt='Inter' />
                <h1 className='sr-only'>Banco Inter</h1>
              </Link>
            </S.Logo>
            <div className='px-md-0 col-2 col-lg-8 offset-6 offset-lg-0 pr-xl-3'>
              <Menu windowWidth={windowWidth} data={data} />
            </div>
          </div>
        </S.HeaderContainer>
      </S.Header>
    </>
  )
}

export default memo(HeaderSiteComponent)
