import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { white, green } from '../../styles/colors'

export const Header = styled.header`
  left: 0;
  height: 64px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  font-family: 'Lato', sans-serif;
  background: rgba(0, 0, 0, 0.7);
  /* opacity 0.3s; */

  @media (min-width: ${breakpoints.md}) {
    height: 80px;
  }

  .logoHeaderOrange {
    display: none;
  }

  .btn-orange {
    background: linear-gradient(167.96deg, #FF8700 0%, #FF500F 100%);
    border-radius: 8px;
    text-transform: uppercase;
    text-align: center;
    color: ${white};
    font-size: 13px;
    font-weight: normal;
    padding: 9px 25px;
    display: inline-block;
    min-width: 180px;
    max-width: 250px;
    transition: all 0.5s ease-in-out;
    outline: none !important;

    &:hover{
      color: ${white};
      background: linear-gradient(0deg, rgba(72, 23, 2, 0.15), rgba(72, 23, 2, 0.15)), linear-gradient(167.96deg, #999999 0%, #999999 100%);
    }
  }

  .fill-white {
    fill: ${white};
  }

  .btn-open-account {
    background-color: ${green.base};
    color: ${white};

    @media (min-width: ${breakpoints.lg}) {
      font-size: 16px;
      text-transform: none;
      border-radius: 8px;
      bottom: auto;
      margin-right: 5px;
      position: relative; 
      width: auto;
      max-width: 180px;
      z-index: 1030;
      padding: 5px 15px;

      max-width: 180px!important;
    }  

    @media (min-width: ${breakpoints.xl}) {
      margin-right: 15px;
    }
  }
`

export const HeaderContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding-left: 15px;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 960px;
    height: 50px;
    padding: 22px 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 2;

    & > div:first-child {
    height: 20px;
  }
  }
  @media (min-width: ${breakpoints.xl}) {
    max-width: 1200px;
  }  
`

export const Logo = styled.div`
  position: relative;
  margin-top: -3px;
  padding-right: 0;
  z-index: 1031;
  height: 50px;

  img {
    height: 27px;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 0;
    margin-top: 0;   

    img {
      width: 199px;
      height: 35px;
    }
  }

`
