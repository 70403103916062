import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import breakpoints from '../../../../styles/breakpoints'
import { animateSpeed, dropMenu } from '../../../../styles/animations'
import { gray, white, orange } from '../../../../styles/colors'

interface IMenu {
  active?: boolean;
}
interface IMenuOption {
  active?: boolean;
}

const menuOptionStyles = css`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${white};
  display: flex;
  font-size: 16px;
  font-weight: 400;
  outline: none !important;
  padding: 13px 20px;
  transition: all 200ms ease-in-out;
  text-align: left;
  text-transform: none;

  @media (min-width: ${breakpoints.lg}) {
    margin: 0 2px;
    padding: 5px 15px;
    position: relative;

    &:hover {
      border-radius: 8px;
      color: ${orange.default};
    }

    ${(props: IMenuOption) => props.active && css`
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 8px;

      &::after {
        bottom: -17px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${white};
        content: "";
        display: block;
        height: 0;
        left: calc(45% - 5px);
        position: absolute;
        transform: rotate(180deg);
        width: 0;
        z-index: 5;
      }

      .arrow {
        transform: rotate(180deg);
      }
    `}
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 5px 20px;
  }

  > svg{
    fill: ${gray[600]};
    margin-right: 10px;
  }

  .arrow {
    display: inline-block;
    height: 20px;
    margin-left: auto;
    position: relative;
    right: 0;
    transition: all ease ${animateSpeed};

    @media (min-width: ${breakpoints.lg}) {
      height: 5px;
      line-height: 1;
      margin-left: 5px;
      margin-right: 0;
      transform-origin: center;
      width: 9px;
    }

    @media (min-width: ${breakpoints.xl}) {
      margin-left: 10px;
    }

    svg {
      fill: ${gray.base};
      margin-right: 0;
      margin-top: -3px;

      @media (min-width: ${breakpoints.lg}) {
        fill: ${white};
        margin-top: auto;
        position: absolute;
      }
    }
  }
`

export const MenuOption = styled.a<IMenuOption>`
  ${css`${menuOptionStyles}`}
`

export const MenuOptionLink = styled(Link)<IMenuOption>`
  ${css`${menuOptionStyles}`}
`

export const MenuBurger = styled.button`
  background-color: transparent;
  border: none;
  outline: none !important;
  padding: 0;
  position: fixed;
  z-index: +9;

  span {
    background-color: ${white};
    border-radius: 2px;
    display: block;
    height: 2px;
    transition: ${animateSpeed} all cubic-bezier(0.68, -0.55, 0.27, 1.55);
    width: 22px;

    &:nth-child(2) {
      margin: 5px 0;
    }
  }
`

export const MenuContainer = styled.ul`
  animation-name: ${dropMenu};
  animation-duration: ${animateSpeed};
  background: rgba(0, 0, 0, 0.7);
  opacity 0.3s;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 50px);
  left: -100%;
  margin: 0;
  overflow-y: scroll;
  padding-top: 100px;
  position: fixed;
  top: 64px;
  transition: all ease-in-out ${animateSpeed};
  width: 100%;
  
  @media (min-width: ${breakpoints.md}) {
    background-color: transparent;
    box-shadow: none;
    left: 0;
    overflow: initial;
    right: 0;
    text-align: right;
    top: -85px;
    width: initial;
    height: 156px;

    @media (min-width: ${breakpoints.lg}) {
      position: relative;
      top: -99px;
    }
      
    @media (min-width: ${breakpoints.lg}) {
      position: relative;
    }
  }


  li {
    text-align: left;
  }

  > li {

    @media (min-width: ${breakpoints.md}) {
      display: inline-block;
      margin-right: 10px;

      &:last-child {
        background: ${orange.extra};
        border-radius: 8px;
        
        a {
          color: ${white};
        }
      }
    }

    @media (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl}) {
      &:last-child {
        a {
          span {
            display: none;
          }
          &:after {
            color: ${white};
          }
        }
      }
    }
  }

  p {
    font-weight: 400;
    line-height: 1.35;
    margin-bottom: 0;
    text-transform: none;
  }
`

export const Menu = styled.nav<IMenu>`
  height: 20px;
  .backdrop-area {
    background-color: rgba(255, 255, 255, 0);
    display: block;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: -1;
  }

  ${(props: IMenu) => {
    if (props.active) {
      return (
        css`
          &::after{
            background-color: rgba(0, 0, 0, 0.5);
            bottom: 0;
            content: '';
            display: block;
            right: 0;
            position: absolute;
            z-index: -1;
            width: 430px;

            @media (min-width: ${breakpoints.lg}) {
              display: none;
            }
          }

          ${MenuContainer} {
            left: 0;
          }

          ${MenuBurger} {
            span {
              &:first-child{
                bottom: -10px;
                transform: rotate(-45deg);
                position: absolute;
                background-color: ${white};
              }
              &:nth-child(2) {
                display: none;
              }
              &:last-child{
                bottom: -10px;
                position: absolute;
                transform: rotate(45deg);
                background-color: ${white};
              }
            }
          }
        `
      )
    }
  }}
`
