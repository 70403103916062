import React from 'react'
import GatsbyLink from 'gatsby-link'
import { ILink } from '../../Header.interface'

/**
 * Função que gera um link com conteúdo do Gatsby
 * @param children
 * @param to
 * @param className
 * @param style
 * @param arialLabel
 * @param title
 */
const Link: React.FC<ILink> = ({ children, to, className, style, ariaLabel, title }: ILink): React.ReactElement => {
  const isGatsbySite = typeof window !== 'undefined' && document.querySelector('#___gatsby')

  return (
    isGatsbySite
      ? (
        <GatsbyLink
          className={className}
          style={style}
          to={to}
          aria-label={ariaLabel}
          title={title + ''}
        >
          {children}
        </GatsbyLink>
      )
      : (
        <a
          className={className}
          style={style}
          href={to}
          aria-label={ariaLabel}
          title={title + ''}
        >
          {children}
        </a>
      )
  )
}

export default Link
